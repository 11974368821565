import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import Form from "../components/Form/Form";

function HomePage({ data, location }) {
  return (
    <Layout location={location}>
      <>
        <Helmet title="Stuntpuppy" />
        {/* <Form /> */}
        {data.allShopifyProduct.edges.map(({ node }) => (
          <li key={node.shopifyId}>
            <h3>
              {/* <Link to={`/products/${node.handle}`}>{node.title}</Link> */}
              <span>{node.title}</span>
              {" - "} {node.shopifyId}
            </h3>
            <p>{node.description}</p>
          </li>
        ))}
      </>
    </Layout>
  );
}

export default HomePage;

export const query = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          id
          title
          shopifyId
          description
          handle
        }
      }
    }
  }
`;
